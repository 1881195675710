// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, getFonts, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import HeaderMalt_logo from "./nb9JNd8Wu";
const HeaderMalt_logoFonts = getFonts(HeaderMalt_logo);

const enabledGestures = {lbElw9j0F: {hover: true}};

const cycleOrder = ["lbElw9j0F"];

const serializationHash = "framer-DIaHY"

const variantClassNames = {lbElw9j0F: "framer-v-1scqs8f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lbElw9j0F", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={"https://www.malt.fr/profile/matthisrsl"}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1scqs8f", className, classNames)} framer-1mgyng2`} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"lbElw9j0F"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backdropFilter: "blur(4px)", backgroundColor: "rgba(248, 251, 255, 0.8)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, WebkitBackdropFilter: "blur(4px)", ...style}} {...addPropertyOverrides({"lbElw9j0F-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-t8hvbx-container"} layoutDependency={layoutDependency} layoutId={"XFdV8Jg4l-container"}><HeaderMalt_logo height={"100%"} id={"XFdV8Jg4l"} layoutId={"XFdV8Jg4l"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-DIaHY[data-border=\"true\"]::after, .framer-DIaHY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DIaHY.framer-1mgyng2, .framer-DIaHY .framer-1mgyng2 { display: block; }", ".framer-DIaHY.framer-1scqs8f { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 8px; height: 46px; justify-content: center; overflow: visible; padding: 8px 24px 8px 24px; position: relative; text-decoration: none; width: 80px; }", ".framer-DIaHY .framer-t8hvbx-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DIaHY.framer-1scqs8f { gap: 0px; } .framer-DIaHY.framer-1scqs8f > * { margin: 0px; margin-bottom: calc(8px / 2); margin-top: calc(8px / 2); } .framer-DIaHY.framer-1scqs8f > :first-child { margin-top: 0px; } .framer-DIaHY.framer-1scqs8f > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hNRNZOjcE":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerS6Mh78YDg: React.ComponentType<Props> = withCSS(Component, css, "framer-DIaHY") as typeof Component;
export default FramerS6Mh78YDg;

FramerS6Mh78YDg.displayName = "header/malt_wrapper";

FramerS6Mh78YDg.defaultProps = {height: 46, width: 80};

addFonts(FramerS6Mh78YDg, [...HeaderMalt_logoFonts])